import {
	CDropdown,
	CDropdownDivider,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
} from "@coreui/react-pro";
import {
	cilLockLocked,
	cilSettings,
	cilUser,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../../hooks/auths";

const AppHeaderDropdown = () => {
	const navigate = useNavigate();
	const { logout } = useLogout();

	return (
		<CDropdown alignment="end" variant="nav-item">
			<CDropdownToggle caret={false} className="py-0">
				<CIcon icon={cilUser} />
			</CDropdownToggle>
			<CDropdownMenu className="pt-0">
				<CDropdownItem className="cursor-pointer" onClick={() => navigate("/settings")}>
					<CIcon className="me-2" icon={cilSettings} />
					Impostazioni
				</CDropdownItem>
				<CDropdownDivider />
				<CDropdownItem className="cursor-pointer" onClick={() => logout()}>
					<CIcon className="me-2" icon={cilLockLocked} />
					Logout
				</CDropdownItem>
			</CDropdownMenu>
		</CDropdown>
	);
};

export default AppHeaderDropdown;
