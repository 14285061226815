/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from "react";

type ToastContextType = {
	message: string;
	isError: boolean;
	setToastNotification: any;
};

const ToastContext = createContext<ToastContextType>({} as any);

export default ToastContext;
