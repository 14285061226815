import { memo, useContext, useState } from "react";
import {
	CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CRow, CSpinner,
} from "@coreui/react-pro";
import { useNavigate, useParams } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { cilPencil } from "@coreui/icons";
import BaseInput from "../../../components/Input/BaseInput/BaseInput";
import ToastContext from "../../../layout/Context/ToastContext/ToastContext";
import AxiosCaller from "../../../utils/AxiosCaller";
import { useService } from "../../../hooks/useServices";

const Insurance = () => {
	const { serviceId } = useParams();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [editErrors, setEditErrors] = useState({} as any);
	const { loading: serviceLoading, service, setService } = useService({ id: serviceId ?? "" });
	const [editMode, setEditMode] = useState(false);

	const [type, setType] = useState("");
	const [name, setName] = useState("");
	const { setToastNotification } = useContext(ToastContext);

	const add = async () => {
		try {
			setIsLoading(true);
			setEditErrors({});

			const params = {
				tipo: service.tipo,
				nome: service.nome,
			};

			const {
				data: { message },
			} = await AxiosCaller.put(`/api/caratteristiche/${serviceId}`, params, {});
			setToastNotification({
				isError: false,
				message,
			});
			navigate("/services");
		} catch (e: any) {
			setToastNotification({
				isError: true,
				message: "Errore durante la modifica del prezzo",
			});
			setEditErrors(e.data);
		} finally {
			setIsLoading(false);
		}
	};

	const onType = (_type: string) => {
		setService({
			...service,
			tipo: _type,
		});
	};
	const onName = (_name: string) => {
		setService({
			...service,
			nome: _name,
		});
	};

	return (
		<>
			<CCard>
				<CCardHeader>
					<CRow className="justify-content-between">
						<CCol className="d-flex justify-content-between align-items-center" md={12}>
							<h5 className="mb-0">Caratteristiche</h5>
							<CButton color="primary" variant="outline" onClick={() => setEditMode(!editMode)}>
								<CIcon className="mw-3" icon={cilPencil} />
							</CButton>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody>
					<CForm className="row g-3 justify-content-center">
						<CCol md={6}>
							<BaseInput
								disabled={!editMode}
								dropdownOptions={[
									"Apri menu",
									{ label: "caratteristica", value: "caratteristica" },
									{ label: "servizio", value: "servizio" },
								]}
								error={editErrors?.tipo}
								initialValue={service?.tipo}
								inputGroupClassName="mb-3"
								setValue={onType}
								stringIcon="Tipo"
								isDropdown
							/>
						</CCol>
						<CCol md={6}>
							<BaseInput
								disabled={!editMode}
								error={editErrors?.nome}
								initialValue={service?.nome}
								inputGroupClassName="mb-3"
								setValue={onName}
								stringIcon="Nome"
							/>
						</CCol>

						<CRow className="align-items-center mt-3">
							<CCol className="d-flex justify-content-center" md={12}>
								<CButton className="px-4" color="primary" disabled={isLoading || !editMode} onClick={add}>
									Aggiorna
								</CButton>
							</CCol>
						</CRow>
					</CForm>
				</CCardBody>
			</CCard>
			{isLoading && <CSpinner />}
		</>
	);
};

export default memo(Insurance);
