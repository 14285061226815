/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { CBadge } from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";

export const AppSidebarNav = ({ items }: { items: any[] }) => {
	const location = useLocation();
	const navLink = (name: any, icon: any, badge?: any) => (
		<>
			{icon && <CIcon customClassName="nav-icon" icon={icon} />}
			{name && name}
			{badge && (
				<CBadge className="ms-auto" color={badge.color}>
					{badge.text}
				</CBadge>
			)}
		</>
	);

	const navItem = (item: any, index: number) => {
		const {
			component, name, badge, icon, ...rest
		} = item;
		const Component = component;
		return (
			<Component
				{...(rest.to
					&& !rest.items && {
					component: NavLink,
				})}
				key={index}
				{...rest}
			>
				{navLink(name, icon, badge)}
			</Component>
		);
	};
	const navGroup = (item: any, index: number) => {
		const {
			component, name, icon, to, ...rest
		} = item;
		const Component = component;
		return (
			<Component
				idx={String(index)}
				key={index}
				toggler={navLink(name, icon)}
				visible={location.pathname.startsWith(to)}
				{...rest}
			>
				{item.items?.map((_item: any, _index: number) => (_item.items ? navGroup(_item, _index) : navItem(_item, _index)))}
			</Component>
		);
	};

	return (
		<>
			{items && items.map((_item, _index) => (_item.items ? navGroup(_item, _index) : navItem(_item, _index)))}
		</>
	);
};

AppSidebarNav.propTypes = {
	items: PropTypes.arrayOf(PropTypes.any).isRequired,
} as any;
