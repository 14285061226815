import axios from "axios";
import { ConcurrencyManager } from "axios-concurrency";
import redirectToLogin from "./reAuth";

const AxiosCaller = axios.create({
	baseURL: import.meta.env.VITE_APP_BACKEND_URL,
	withCredentials: true,
	// headers: { api: "true" },
});
// A concurrency parameter of 1 makes all api requests sequential
const MAX_CONCURRENT_REQUESTS = 25;

// init your manager.
ConcurrencyManager(AxiosCaller, MAX_CONCURRENT_REQUESTS);

AxiosCaller.interceptors.response.use(
	(response) => Promise.resolve(response),
	(error) => {
		if (error.response && error.response.status === 401) {
			return redirectToLogin();
		}

		return Promise.reject(error?.response || error);
	},
);

export default AxiosCaller;
