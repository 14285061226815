import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { NotificationsProvider } from "reapop";
import { BrowserRouter } from "react-router-dom";
import { Offline } from "react-detect-offline";
import { store } from "./store/store";

import i18nInit from "./utils/i18nInit";

import translationsIT from "./assets/locales/translations-it.json";
import translationsEN from "./assets/locales/translations-en.json";

import App from "./App";

import "../scss/app.scss";

i18nInit("translation", translationsEN, translationsIT);

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<NotificationsProvider>
					<Offline polling={{
						url: `${import.meta.env.VITE_APP_API_URL}/api/online`, interval: 10000, enabled: true, timeout: 20000,
					}}
					>
						<div className="offline" />
					</Offline>
					<App />
				</NotificationsProvider>
			</Provider>
		</BrowserRouter>
	</React.StrictMode>,
);
