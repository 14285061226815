import React, {
	Suspense, useMemo, useRef, useState,
} from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
	CContainer, CSpinner, CToast, CToastBody, CToaster, CToastHeader,
} from "@coreui/react-pro";

// routes config
import routes from "../routes";
import ToastContext from "./Context/ToastContext/ToastContext";

export type ToastNotificationType = { message: string; isError: boolean };

const AppContent = () => {
	const [toastNotification, setToastNotification] = useState<ToastNotificationType>({
		message: "",
		isError: false,
	});

	const setToastAndDelay = (value: ToastNotificationType) => {
		setToastNotification(value);
		setTimeout(() => {
			setToastNotification({ message: "", isError: false });
		}, 3000);
	};

	const toastContext = useMemo(
		() => ({ ...toastNotification, setToastNotification: setToastAndDelay }),
		[toastNotification],
	);
	return (
		<>
			{toastNotification?.message && (
				<div
					className="position-fixed top-0 end-0 p-3 d-flex align-items-center"
					style={{
						zIndex: 11111111,
						backgroundColor: "#fff",
						border: "1px solid rgba(0, 0, 21, 0.175)",
						borderRadius: "5px",
					}}
				>
					<svg
						className="rounded me-2"
						focusable="false"
						height="20"
						preserveAspectRatio="xMidYMid slice"
						role="img"
						width="20"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect fill={toastNotification.isError ? "red" : "green"} height="100%" width="100%" />
					</svg>
					{toastNotification.message}
				</div>
			)}
			<ToastContext.Provider value={toastContext}>
				<CContainer fluid>
					<Suspense fallback={<CSpinner color="primary" />}>
						<Routes>
							{routes.map(
								(route) => route.element && (
									<Route element={<route.element />} exact={route.exact} key={route.path} path={route.path} />
								),
							)}
							<Route element={<Navigate to="/macro" replace />} path="/" />
						</Routes>
					</Suspense>
				</CContainer>
			</ToastContext.Provider>
		</>
	);
};

export default React.memo(AppContent);
