import { useEffect, useState } from "react";

/**
 * Returns {width, height} of the window
 */
const useWindowSizes = () => {
	const [windowSizes, setWindowSizes] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		const onResize = () => {
			setWindowSizes({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener("resize", onResize);

		return () => {
			window.removeEventListener("resize", onResize);
		};
	}, []);

	return windowSizes;
};

export { useWindowSizes };
export default useWindowSizes;