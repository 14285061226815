import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

const i18nInit = (appNameSpace = "translation", appTranslationEN = {}, appTranslationIT = {}) => {
	const resources = {
		en: {
			[appNameSpace]: appTranslationEN,
		},
		it: {
			[appNameSpace]: appTranslationIT,
		},
	};

	return i18next
		.use(detector)
		.use(initReactI18next) // passes i18n down to react-i18next
		.init({
			resources,
			fallbackLng: ["en", "it"],
			// lng: 'it',
			keySeparator: false,
			interpolation: {
				escapeValue: false, // react already safes from xss
			},
			react: {
				useSuspense: false,
			},
		});
};

export default i18nInit;
