import {
	cilSettings, cilBook, cilCarAlt, cilLibrary, cilEuro, cilShieldAlt, cilTags,
} from "@coreui/icons";
import { CNavItem, CNavTitle } from "@coreui/react-pro";

const _nav = [
	{
		component: CNavTitle,
		name: "Nolovan",
	},
	{
		component: CNavItem,
		name: "Macrocategorie",
		to: "/macro",
		icon: cilLibrary,
	},
	{
		component: CNavItem,
		name: "Microcategorie",
		to: "/micro",
		icon: cilBook,
	},
	{
		component: CNavItem,
		name: "Veicoli",
		to: "/vehicle",
		icon: cilCarAlt,
	},
	// {
	// 	component: CNavTitle,
	// 	name: "Prezzi",
	// },
	// {
	// 	component: CNavItem,
	// 	name: "Micro categorie",
	// 	to: "/vehicle-prices",
	// 	icon: cilEuro,
	// },
	// {
	// 	component: CNavTitle,
	// 	name: "Servizi",
	// },
	// {
	// 	component: CNavItem,
	// 	name: "Assicurazioni",
	// 	to: "/insurance-prices",
	// 	icon: cilShieldAlt,
	// },
	// {
	// 	component: CNavItem,
	// 	name: "Caratteristiche",
	// 	to: "/services",
	// 	icon: cilTags,
	// },
	{
		component: CNavTitle,
		name: "Impostazioni",
	},
	{
		component: CNavItem,
		name: "Impostazioni",
		to: "/settings",
		icon: cilSettings,
	},
];

export default _nav;
