import { CSpinner } from "@coreui/react-pro";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import BaseInput from "../../../components/Input/BaseInput/BaseInput";
import { useUploadDocument } from "../../../hooks/practices";
import useCheckUrlSignature from "../../../hooks/useCheckUrlSignature";

import Page404 from "../../page404/Page404";

import classes from "./UploadDocuments.module.scss";

const UploadDocuments = () => {
	const [searchParams] = useSearchParams();
	const [fileError, setFileError] = useState<string>();
	const uploadUrl = searchParams.get("url");
	const { checkingSignature, isValidSignature } = useCheckUrlSignature({
		signedUrl: uploadUrl as string,
		initialFetch: true,
	});
	const {
		loading, errors, uploadDocument,
	} = useUploadDocument({ signedUrl: uploadUrl as string });

	if (!uploadUrl) {
		return (
			<Page404 />
		);
	}

	if (checkingSignature) {
		return (
			<CSpinner className={classes.loader} />
		);
	}

	if (!isValidSignature) {
		return (
			<div className={classes.invalidContainer}>
				Il link di caricamento dei documenti é scaduto.
			</div>
		);
	}

	return (
		<div className={classes.uploadDocuments}>
			customers upload page

			<BaseInput
				error={fileError || errors?.doc}
				inputGroupClassName="mb-3"
				loading={loading}
				maxFileSize={4096}
				setError={(error) => setFileError(error)}
				setValue={(val) => {
					uploadDocument(val[0] as File, "altro");
				}}
				type="file"
			/>
		</div>
	);
};

export default UploadDocuments;
