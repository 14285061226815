import { Navigate } from "react-router";
import { useAuthenticated } from "../../store/selectors";

import AppContent from "../../layout/AppContent";
import AppFooter from "../../layout/AppFooter";
import AppHeader from "../../layout/AppHeader";
import AppSidebar from "../../layout/AppSidebar";

const Home = () => {
	const authenticated = useAuthenticated();

	if (!authenticated) return <Navigate to="/login" />;

	return (
		<>
			<AppSidebar />
			<div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
				<AppHeader />
				<div className="body flex-grow-1 px-3">
					<AppContent />
				</div>
				<AppFooter />
			</div>
		</>
	);
};

export default Home;