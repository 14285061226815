/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from "react";
import AxiosCaller from "../utils/AxiosCaller";

export const useCheckUrlSignature = ({
	signedUrl,
	method = "post",
	initialFetch = false,
}: {
	signedUrl: string,
	method?: string,
	initialFetch?: boolean,
}) => {
	const [checkingSignature, setCheckingSignature] = useState<boolean>(true);
	const [isValidSignature, setIsValidSignature] = useState<boolean>(false);

	const checkSignature = useCallback(async () => {
		try {
			setCheckingSignature(true);

			await AxiosCaller.request({
				method,
				url: signedUrl,
			});

			setIsValidSignature(true);
		} catch (e: any) {
			if (e?.status === 403) setIsValidSignature(false);
			else setIsValidSignature(true);
		} finally {
			setCheckingSignature(false);
		}
	}, [method, signedUrl]);

	useEffect(() => {
		if (signedUrl && initialFetch) {
			checkSignature();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		checkingSignature,
		isValidSignature,
		checkSignature,
	};
};

export default useCheckUrlSignature;
