/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/sort-comp */

import React from "react";

import classes from "./ErrorBoundary.module.scss";

class ErrorBoundary extends React.Component {
	_isMounted = false;

	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	logErrorToMyService(error: any, info: any) {
		console.log(error);
		console.log(info);
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidCatch(error: any, info: any) {
		// Display fallback UI
		this._isMounted && this.setState({ hasError: true });
		// You can also log the error to an error reporting service
		this.logErrorToMyService(error, info);
	}

	render() {
		if ((this.state as any).hasError) {
			// You can render any custom fallback UI
			return (
				<div className={classes.mainError}>
					<h1>OOPS! Something went wrong.</h1>
				</div>
			);
		}
		return (this.props as any).children;
	}
}

export default ErrorBoundary;
