import { memo, useContext, useState } from "react";
import {
	CButton, CCard, CCardBody, CCardHeader, CCol, CForm, CRow, CSpinner,
} from "@coreui/react-pro";
import { useNavigate } from "react-router-dom";
import BaseInput from "../../../components/Input/BaseInput/BaseInput";
import ToastContext from "../../../layout/Context/ToastContext/ToastContext";
import AxiosCaller from "../../../utils/AxiosCaller";

const Insurance = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [editErrors, setEditErrors] = useState({} as any);

	const [type, setType] = useState("");
	const [name, setName] = useState("");
	const { setToastNotification } = useContext(ToastContext);

	const add = async () => {
		try {
			setIsLoading(true);
			setEditErrors({});

			const params = {
				tipo: type,
				nome: name,
			};

			const {
				data: { message },
			} = await AxiosCaller.post("/api/caratteristiche", params, {});
			setToastNotification({
				isError: false,
				message,
			});
			navigate("/services");
		} catch (e: any) {
			setToastNotification({
				isError: true,
				message: "Errore durante l'aggiunta del prezzo",
			});
			setEditErrors(e.data);
		} finally {
			setIsLoading(false);
		}
	};

	const onType = (_type: string) => {
		setType(_type);
	};
	const onName = (_name: string) => {
		setName(_name);
	};

	return (
		<>
			<CCard>
				<CCardHeader>
					<CRow className="justify-content-between">
						<CCol className="d-flex justify-content-between align-items-center" md={12}>
							<h5 className="mb-0">Caratteristiche</h5>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody>
					<CForm className="row g-3 justify-content-center">
						<CCol md={6}>
							<BaseInput
								dropdownOptions={[
									"Apri menu",
									{ label: "caratteristica", value: "caratteristica" },
									{ label: "servizio", value: "servizio" },
								]}
								error={editErrors?.tipo}
								initialValue={type}
								inputGroupClassName="mb-3"
								setValue={onType}
								stringIcon="Tipo"
								isDropdown
							/>
						</CCol>
						<CCol md={6}>
							<BaseInput
								error={editErrors?.nome}
								initialValue={name}
								inputGroupClassName="mb-3"
								setValue={onName}
								stringIcon="Nome"
							/>
						</CCol>

						<CRow className="align-items-center mt-3">
							<CCol className="d-flex justify-content-center" md={12}>
								<CButton className="px-4" color="primary" disabled={isLoading} onClick={add}>
									Aggiungi
								</CButton>
							</CCol>
						</CRow>
					</CForm>
				</CCardBody>
			</CCard>
			{isLoading && <CSpinner />}
		</>
	);
};

export default memo(Insurance);
