// libraries
import {
	Routes, Route,
} from "react-router-dom";
import GuestRoute from "./components/Routes/GuestRoute";
import UploadDocuments from "./pages/Customers/UploadDocuments/UploadDocuments";
import Home from "./pages/Home/Home";

// pages
import Login from "./pages/Login/Login";

const MainLayout = () => (
	<Routes>
		<Route
			element={(
				<GuestRoute>
					<Login />
				</GuestRoute>
			)}
			path="/login"
		/>
		<Route
			element={(
				<UploadDocuments />
			)}
			path="/customers/upload"
		/>
		<Route
			element={<Home />}
			path="*"
		/>
	</Routes>
);

export default MainLayout;
