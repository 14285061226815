/* eslint-disable @typescript-eslint/no-explicit-any */
import { HTMLInputTypeAttribute } from "react";
import CIcon from "@coreui/icons-react";
import {
	CFormInput, CFormSelect, CInputGroup, CInputGroupText, CMultiSelect, CSpinner,
} from "@coreui/react-pro";
import { DefaultTFuncReturn } from "i18next";

import cx from "classnames";

import classes from "./BaseInput.module.scss";

type BaseInputProps = {
	type?: HTMLInputTypeAttribute;
	loading?: boolean;
	disabled?: boolean;
	multiple?: boolean;
	error?: string | string[];
	placeholder?: string | DefaultTFuncReturn;
	icon?: string | string[];
	stringIcon?: string;
	inputGroup?: boolean;
	inputClassName?: string;
	inputGroupClassName?: string;
	initialValue?: string | number;
	label?: string;
	maxFileSize?: number;
	setValue?: (value: string | File[]) => any;
	setValues?: (value: any[]) => any;
	setError?: (error: string) => any;
	isDropdown?: boolean;
	dropdownOptions?: { label: string; value: string; disabled?: boolean; selected?: boolean }[];
	isMultiselect?: boolean;
	multiSelectOptions?: { name: string; code: string }[];
};

export const BaseInput = ({
	type = "text",
	disabled = false,
	error = "",
	setValues,
	isMultiselect = false,
	multiSelectOptions = [],
	placeholder = "",
	icon,
	label = "",
	stringIcon,
	inputClassName = "",
	inputGroupClassName = "",
	setValue = () => null,
	inputGroup = false,
	maxFileSize = 1000000,
	multiple = false,
	setError = () => null,
	loading = false,
	initialValue,
	dropdownOptions = [],
	isDropdown = false,
}: BaseInputProps) => {
	const _error = Array.isArray(error) ? error?.[0] : error;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onChangeValue = (evt: any) => {
		setError("");
		if (type === "file") {
			const files = evt.target.files as File[];

			for (let i = 0; i < files.length; i++) {
				if (files[i].size > maxFileSize * 1000) {
					setError(`La grandezza massima ammessa di un file è ${maxFileSize} kB`);
					setValue([]);
					return;
				}
			}

			setValue(files);
		} else if (isMultiselect && !!setValues) {
			setValues(evt);
		} else setValue(evt.target.value as string);
	};

	if (isMultiselect) {
		// if (!multiSelectOptions.length) return null;
		return (
			<CMultiSelect
				aria-label="Default select example"
				className={cx(inputClassName, { [classes.errorBorder]: !!_error, [classes.loading]: loading })}
				disabled={disabled}
				feedbackInvalid={_error}
				invalid={!!_error}
				options={multiSelectOptions as any}
				placeholder={stringIcon}
				onChange={onChangeValue}
			/>
		);
	}

	if (isDropdown) {
		return (
			<CInputGroup className={inputGroupClassName}>
				{stringIcon && <CInputGroupText>{stringIcon}</CInputGroupText>}
				<CFormSelect
					aria-label="Default select example"
					className={cx(inputClassName, { [classes.errorBorder]: !!_error, [classes.loading]: loading })}
					disabled={disabled}
					feedbackInvalid={_error}
					invalid={!!_error}
					options={dropdownOptions}
					value={initialValue}
					onChange={onChangeValue}
				/>
			</CInputGroup>
		);
	}

	if (inputGroup || !!inputGroupClassName) {
		return (
			<CInputGroup className={inputGroupClassName}>
				{icon && (
					<CInputGroupText>
						<CIcon icon={icon} />
					</CInputGroupText>
				)}
				{stringIcon && <CInputGroupText>{stringIcon}</CInputGroupText>}
				<CFormInput
					autoComplete="new-password"
					className={cx(inputClassName, { [classes.errorBorder]: !!_error, [classes.loading]: loading })}
					defaultValue={initialValue || ""}
					disabled={disabled || loading}
					feedbackInvalid={_error}
					invalid={!!_error}
					label={label}
					multiple={multiple}
					placeholder={placeholder as string}
					type={type}
					onChange={onChangeValue}
				/>

				{loading && <CSpinner className={classes.spinner} />}
			</CInputGroup>
		);
	}

	return (
		<>
			{icon && (
				<CInputGroupText>
					<CIcon icon={icon} />
				</CInputGroupText>
			)}
			{stringIcon && <CInputGroupText>{stringIcon}</CInputGroupText>}
			<CFormInput
				autoComplete="new-password"
				className={cx(inputClassName, { [classes.errorBorder]: !!_error, [classes.loading]: loading })}
				defaultValue={initialValue || ""}
				disabled={disabled || loading}
				feedbackInvalid={_error}
				invalid={!!_error}
				label={label}
				multiple={multiple}
				placeholder={placeholder as string}
				type={type}
				onChange={onChangeValue}
			/>
			{loading && <CSpinner className={classes.spinner} />}
		</>
	);
};

export default BaseInput;
