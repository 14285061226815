/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigate } from "react-router-dom";
import { useAuthenticated } from "../../store/selectors";

type GuestRouteProps = {
	children: any,
	to?: string,
}

const GuestRoute = ({
	children,
	to,
}: GuestRouteProps) => {
	const authenticated = useAuthenticated();

	if (authenticated) {
		return <Navigate to={to || "/"} />;
	}

	// authorized so return child components
	return children;
};

export default GuestRoute;
