/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from "react";
import AxiosCaller from "../utils/AxiosCaller";

export const useServices = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const [services, setServices] = useState([]);
	const [errors, setErrors] = useState<ErrorBag | null>(null);

	const fetchServices = useCallback(async () => {
		try {
			setLoading(true);
			setErrors(null);
			const { data: _services } = (await AxiosCaller.get("/api/caratteristiche", {
				params: {},
			})) as any;

			setServices(_services);
		} catch (e: any) {
			setErrors(e?.data?.errors || null);
		} finally {
			setLoading(false);
		}

		return false;
	}, []);

	useEffect(() => {
		fetchServices();
	}, [fetchServices]);

	return {
		services,
		loading,
		errors,
		fetchServices,
	};
};

export const useService = ({ id }: { id: number | string }) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [service, setService] = useState<any>(null);
	const [errors, setErrors] = useState<ErrorBag | null>(null);

	const fetchService = useCallback(async () => {
		try {
			setLoading(true);
			setErrors(null);

			const { data }: { data: any } = await AxiosCaller.get(`/api/caratteristiche/${id}`);

			setService(data);
		} catch (e: any) {
			setErrors(e?.data?.errors || null);
		} finally {
			setLoading(false);
		}
	}, [id]);

	useEffect(() => {
		fetchService();
	}, [fetchService]);

	return {
		service,
		setService,
		loading,
		errors,
	};
};
