/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from "react";
import AxiosCaller from "../utils/AxiosCaller";

export interface ITablePractice {
	id: number;
	codice: string;
	created_at: string;
	email: string;
	nome: string;
	cognome: string;
	price: number;
	status: string;
}

export const useAllPractices = ({
	page = 1, search = "", per_page = 20, sort_by = "created_at", sort_direction = "desc",
}) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [from, setFrom] = useState<number>(0);
	const [to, setTo] = useState<number>(0);
	const [total, setTotal] = useState<number>(0);
	const [last_page, setLastPage] = useState<number>(1);
	const [current_page, setCurrentPage] = useState<number>(1);
	const [data, setData] = useState<ITablePractice[]>([]);
	const [errors, setErrors] = useState<ErrorBag | null>(null);

	const fetchAllPractice = useCallback(async () => {
		try {
			setLoading(true);
			setErrors(null);

			const {
				data: {
					from: _from,
					to: _to,
					total: _total,
					last_page: _last_page,
					current_page: _current_page,
					data: _data,
				},
			}: {
				data: {
					from: number,
					to: number,
					total: number,
					last_page: number,
					current_page: number,
					data: ITablePractice[],
				}
			} = await AxiosCaller.get("/api/legge10", {
				params: {
					page,
					search,
					per_page,
					sort_by,
					sort_direction,
				},
			});

			setFrom(_from);
			setTo(_to);
			setFrom(_from);
			setTotal(_total);
			setLastPage(_last_page);
			setCurrentPage(_current_page);
			setData(_data);
		} catch (e: any) {
			setErrors(e?.data?.errors || null);
		} finally {
			setLoading(false);
		}

		return false;
	}, [page, per_page, search, sort_by, sort_direction]);

	useEffect(() => {
		fetchAllPractice();
	}, [fetchAllPractice]);

	return {
		from,
		to,
		total,
		last_page,
		current_page,
		data,
		loading,
		errors,
	};
};

export const usePractice = ({
	id,
}: { id: number | string }) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [practice, setPractice] = useState<Practice | null>(null);
	const [errors, setErrors] = useState<ErrorBag | null>(null);

	const fetchPractice = useCallback(async () => {
		try {
			setLoading(true);
			setErrors(null);

			const { data }: { data: Practice } = await AxiosCaller.get(`/api/legge10/${id}`);

			setPractice(data);
		} catch (e: any) {
			setErrors(e?.data?.errors || null);
		} finally {
			setLoading(false);
		}
	}, [id]);

	useEffect(() => {
		fetchPractice();
	}, [fetchPractice]);

	return {
		practice,
		loading,
		errors,
	};
};

export const useUploadDocument = ({
	signedUrl,
}: { signedUrl: string }) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<ErrorBag | null>(null);
	const [message, setMessage] = useState<string | null>();

	const uploadDocument = useCallback(async (file: File, type: string) => {
		try {
			setLoading(true);
			setErrors(null);
			setMessage(null);

			const { data: { message: _message } }: { data: { message: string } } = await AxiosCaller.post(
				signedUrl,
				{
					type,
					doc: file,
					name: file.name,
				},
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				},
			);

			setMessage(_message);
		} catch (e: any) {
			setErrors(e?.data?.errors || null);
		} finally {
			setLoading(false);
		}
	}, [signedUrl]);

	return {
		message,
		loading,
		errors,
		uploadDocument,
	};
};
