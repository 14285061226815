import { format } from "date-fns";

export const humanize = (str: any) => {
	if (str === "created_at") return "Creata il";
	if (str === "updated_at") return "Aggiornata il";
	if (str === "active") return "Attiva";
	let i;
	const frags = str.split("_");
	for (i = 0; i < frags.length; i++) {
		frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
	}
	return frags.join(" ");
};

export const humanizeValue = (key: any, value: any) => {
	if (key === "created_at") return format(new Date(value), "dd/LL/yyyy HH:mm");
	if (key === "updated_at") return format(new Date(value), "dd/LL/yyyy HH:mm");
	if (["gancio_traino", "active"].includes(key)) return value ? "Si" : "No";
	return value?.toString()?.replace("_", " ");
};
