import { lazy } from "react";
import EditService from "./pages/Service/EditService/EditService";

import Service from "./pages/Service/Service/Service";
import Services from "./pages/Service/Services";

const MacroCategories = lazy(() => import("./pages/Auth/MacroCategories/MacroCategories"));
const MicroCategories = lazy(() => import("./pages/Auth/MicroCategories/MicroCategories"));
const Vehicles = lazy(() => import("./pages/Auth/Vehicles/Vehicles"));
const EditMacro = lazy(() => import("./pages/Auth/MacroCategory/EditMacro/EditMacro"));
const EditMicro = lazy(() => import("./pages/Auth/MicroCategory/EditMicro/EditMicro"));
const EditVehicle = lazy(() => import("./pages/Auth/Vehicle/EditVehicle/EditVehicle"));
const MacroCategory = lazy(() => import("./pages/Auth/MacroCategory/MacroCategory"));
const MicroCategory = lazy(() => import("./pages/Auth/MicroCategory/MicroCategory"));
const Vehicle = lazy(() => import("./pages/Auth/Vehicle/Vehicle"));
const VehiclesPrice = lazy(() => import("./pages/Price/Vehicles/Vehicles"));
const InsurancesPrice = lazy(() => import("./pages/Price/Insurances/Insurances"));
const InsurancesPriceAdd = lazy(() => import("./pages/Price/Insurance/Insurance"));
const VehiclesPriceAdd = lazy(() => import("./pages/Price/Vehicle/Vehicle"));
const EditInsurance = lazy(() => import("./pages/Price/Insurance/EditInsurance/EditInsurance"));
const EditVehiclePrice = lazy(() => import("./pages/Price/Vehicle/EditVehicle/EditVehicle"));

export interface IRoute {
	path: string;
	name: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	element?: any;
	exact?: boolean;
}

const routes = [
	{ path: "/macro", name: "Macrocategorie", element: MacroCategories },
	{
		path: "/macro/add",
		name: "Aggiungi",
		element: MacroCategory,
		exact: true,
	},
	{
		path: "/macro/:macroId",
		name: "Modifica",
		element: EditMacro,
		exact: true,
	},
	{ path: "/micro", name: "Microcategorie", element: MicroCategories },
	{
		path: "/micro/add",
		name: "Aggiungi",
		element: MicroCategory,
		exact: true,
	},
	{
		path: "/micro/:microId",
		name: "Modifica",
		element: EditMicro,
		exact: true,
	},
	{ path: "/vehicle", name: "Veicoli", element: Vehicles },
	{
		path: "/vehicle/add",
		name: "Aggiungi",
		element: Vehicle,
		exact: true,
	},
	{
		path: "/vehicle/:vehicleId",
		name: "Modifica",
		element: EditVehicle,
		exact: true,
	},
	{
		path: "/vehicle-prices",
		name: "Prezzi",
		element: VehiclesPrice,
		exact: true,
	},
	{
		path: "/vehicle-prices/add",
		name: "Prezzi",
		element: VehiclesPriceAdd,
		exact: true,
	},
	{
		path: "/vehicle-prices/:priceId",
		name: "Modifica",
		element: EditVehiclePrice,
		exact: true,
	},
	{
		path: "/insurance-prices",
		name: "Prezzi",
		element: InsurancesPrice,
		exact: true,
	},
	{
		path: "/insurance-prices/:insuranceId",
		name: "Modifica",
		element: EditInsurance,
		exact: true,
	},
	{
		path: "/insurance-price/add",
		name: "Prezzi",
		element: InsurancesPriceAdd,
		exact: true,
	},
	{
		path: "/services",
		name: "Caratteristiche",
		element: Services,
		exact: true,
	},
	{
		path: "/services/add",
		name: "Caratteristiche",
		element: Service,
		exact: true,
	},
	{
		path: "/services/:serviceId",
		name: "Caratteristiche",
		element: EditService,
		exact: true,
	},
] as IRoute[];

export default routes;
