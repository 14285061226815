import {
	CCol,
	CContainer,
	CRow,
} from "@coreui/react-pro";
import { useTranslation } from "react-i18next";

const Page404 = () => {
	const { t } = useTranslation();

	return (
		<div className="bg-light min-vh-100 d-flex flex-row align-items-center">
			<CContainer>
				<CRow className="justify-content-center">
					<CCol md={6}>
						<div className="clearfix">
							<h1 className="float-start display-3 me-4">404</h1>
							<h4 className="pt-3">{t("oops404")}</h4>
							<p className="text-medium-emphasis float-start">
								{t("desc404")}
							</p>
						</div>
					</CCol>
				</CRow>
			</CContainer>
		</div>
	);
};

export default Page404;
