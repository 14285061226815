import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
	CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";

import SimpleBar from "simplebar-react";
import { SET_SIDEBAR_SHOW, SET_SIDEBAR_UNFOLDABLE } from "../store/constants";
import { sygnet } from "../assets/brand/sygnet";

import { AppSidebarNav } from "./AppSidebarNav";

// sidebar nav config
import navigation from "../_nav";

const AppSidebar = () => {
	const dispatch = useDispatch();
	const unfoldable = useSelector(({ base }: { base: IBase }) => base.sidebarUnfoldable);
	const sidebarShow = useSelector(({ base }: { base: IBase }) => base.sidebarShow);

	return (
		<CSidebar
			position="fixed"
			unfoldable={unfoldable}
			visible={sidebarShow}
			onVisibleChange={(visible) => {
				dispatch({ type: SET_SIDEBAR_SHOW, payload: visible });
			}}
		>
			<CSidebarBrand className="d-none d-md-flex">
				<img alt="nolovan.it" className="img-fluid p-3 pt-0 pb-0" src="https://www.nolovan.it/images/logo.png" />
				<CIcon className="sidebar-brand-narrow" height={35} icon={sygnet} />
			</CSidebarBrand>
			<CSidebarNav>
				<SimpleBar>
					<AppSidebarNav items={navigation} />
				</SimpleBar>
			</CSidebarNav>
			<CSidebarToggler
				className="d-none d-md-flex"
				onClick={() => dispatch({ type: SET_SIDEBAR_UNFOLDABLE, payload: !unfoldable })}
			/>
		</CSidebar>
	);
};

export default React.memo(AppSidebar);
