/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import cx from "classnames";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react-pro";
import routes, { IRoute } from "../../routes";

import classes from "./AppBreadcrumb.module.scss";

export interface IBreadcrumb {
	pathname: string;
	name: string;
	active: boolean;
}

const AppBreadcrumb = () => {
	const currentLocation = useLocation().pathname;
	const navigate = useNavigate();

	const getRouteName = (pathname: string, _routes: IRoute[]) => {
		const currentRoute = _routes.find((route) => matchPath({ path: route.path }, pathname));
		return currentRoute ? currentRoute.name : false;
	};

	const getBreadcrumbs = (location: string) => {
		const breadcrumbs = [] as IBreadcrumb[];
		location.split("/").reduce((prev, curr, index, array) => {
			const currentPathname = `${prev}/${curr}`;
			const routeName = getRouteName(currentPathname, routes);

			if (routeName) {
				breadcrumbs.push({
					pathname: currentPathname,
					name: routeName,
					active: index + 1 === array.length,
				});
			}

			return currentPathname;
		});

		return breadcrumbs;
	};

	const breadcrumbs = getBreadcrumbs(currentLocation);

	return (
		<CBreadcrumb className="m-0 ms-2">
			<CBreadcrumbItem
				className={classes.link}
				onClick={() => navigate("/")}
			>
				Home
			</CBreadcrumbItem>
			{breadcrumbs.map((breadcrumb) => (
				<CBreadcrumbItem
					className={cx({ [classes.link]: !breadcrumb.active })}
					key={breadcrumb.pathname}
					onClick={() => !breadcrumb.active && navigate(breadcrumb.pathname)}
				>
					{breadcrumb.name}
				</CBreadcrumbItem>
			))}
		</CBreadcrumb>
	);
};

export default React.memo(AppBreadcrumb);
