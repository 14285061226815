import { cilPencil, cilPlus, cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
	CButton,
	CCard,
	CCardBody,
	CCardHeader,
	CCol,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
	CRow,
} from "@coreui/react-pro";
import { memo, useContext, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useServices } from "../../hooks/useServices";
import ToastContext from "../../layout/Context/ToastContext/ToastContext";
import AxiosCaller from "../../utils/AxiosCaller";
import { humanize, humanizeValue } from "../../utils/humanize";

const Services = ({ className }: { className: string }) => {
	const { services, loading, fetchServices } = useServices();
	const [rowPerPage, setRowPerPage] = useState(10);
	const navigate = useNavigate();
	const [visible, setVisible] = useState(false);
	const [selectedService, setSelectedService] = useState({} as any);
	const { setToastNotification } = useContext(ToastContext);

	const getColumns = () => {
		if (!services || !services.length) return [];

		const columns = Object.keys(services[0]).map(
			(price) => ({
				id: price,
				name: humanize(price),
				sortable: true,
				format: (row: any) => humanizeValue(price, row[price]),
				selector: (row: any) => row?.[price],
			} as any),
		);

		columns.push({
			cell: (service: any) => <CIcon icon={cilTrash} onClick={() => onDelete(service)} />,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		});

		columns.push({
			cell: (service: any) => <CIcon icon={cilPencil} onClick={() => navigate(`/services/${service.id}`)} />,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		});
		return columns;
	};

	const onDeleteConfirm = async () => {
		try {
			const {
				data: { message },
			} = await AxiosCaller.delete(`/api/caratteristiche/${selectedService?.id}`);
			setToastNotification({
				isError: false,
				message,
			});
			fetchServices();
			setVisible(false);
		} catch (e: unknown) {
			setVisible(false);
			setToastNotification({
				isError: true,
				message: "Errore durante il cancellamento della caratteristica",
			});
		}
	};

	const onDelete = async (micro: any) => {
		setSelectedService(micro);
		setVisible(true);
	};

	const onClose = () => {
		setVisible(false);
	};

	const addService = () => {
		navigate("/services/add");
	};
	return (
		<>
			<CModal alignment="center" backdrop="static" visible={visible} onClose={onClose}>
				<CModalHeader>
					<CModalTitle>{selectedService?.nome}</CModalTitle>
				</CModalHeader>
				<CModalBody>Sei sicuro di voler eliminare la caratteristica?</CModalBody>
				<CModalFooter>
					<CButton color="secondary" onClick={onClose}>
						Close
					</CButton>
					<CButton color="primary" onClick={onDeleteConfirm}>
						Delete
					</CButton>
				</CModalFooter>
			</CModal>
			<CCard>
				<CCardHeader>
					<CRow className="justify-content-between">
						<CCol className="d-flex justify-content-between align-items-center" md={12}>
							<h5 className="mb-0">Caratteristiche</h5>
							<CButton color="primary" variant="outline" onClick={addService}>
								<CIcon className="mw-3" icon={cilPlus} />
							</CButton>
						</CCol>
					</CRow>
				</CCardHeader>
				<CCardBody>
					<DataTable
						className={className}
						columns={getColumns()}
						data={services}
						paginationPerPage={rowPerPage}
						paginationRowsPerPageOptions={[10, 20, 50, 100]}
						paginationTotalRows={services.length}
						progressPending={loading}
						highlightOnHover
						pagination
						paginationServer
						pointerOnHover
						onChangeRowsPerPage={(perPage: number) => setRowPerPage(perPage)}
						onRowClicked={(macro: any) => {
							navigate(`/services/${macro.id}`);
						}}
					/>
				</CCardBody>
			</CCard>
		</>
	);
};

export default memo(Services);
